
























import { Component, Vue } from 'vue-property-decorator'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import LogoService from '@/services/LogoService'

@Component({
  components: {
    LayoutCompact
  }
})
export default class ResetPasswordSuccess extends Vue {
  public image: any = ''

  async created() {
    await LogoService.getAvatar().then((res: any) => {
      this.image = res.data.items
    })
  }

  public handleBackLogin() {
    this.$router.push({ name: 'login' })
  }
}
